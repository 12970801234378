import PropTypes from 'prop-types';
import React, { useEffect, lazy, Suspense } from 'react';
import { Link, navigate } from 'gatsby';

const ResetPageComponent = lazy(() => import('components/Page_Reset'));

ResetPasswordPage.propTypes = {
  location: PropTypes.object
};

function ResetPasswordPage({ location }) {
  const token = location.search.split('=');
  const validToken = token[1];

  useEffect(() => {
    if (validToken) return;
    navigate('/login/');
  });

  return validToken ? (
    <Suspense fallback="">
      <ResetPageComponent token={validToken} />
    </Suspense>
  ) : (
    <Link to="/login" style={{ display: 'none' }}>
      Go to Login Page
    </Link>
  );
}

export default ResetPasswordPage;
